import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import videoURL from 'constants/helpVideoUrl';
import TopMenu from '../../../../../components/TopMenu';
import ListAddCategory from '../ListAddCategory';
import Iconify from '../../../../../components/Iconify';
import { HeadArea } from './styled-components';
import { AccordionStyle } from '../../styled-components';
import { FormContext } from '../../context/StepperContext';
import { Wrapper } from '../../../setup/styled-components';

export default function Step3({ skipStep }) {
    const {
        categoriesMasterData: categoryGroups,
        openAccordionsStep3,
        onCategoryAccordionClick,
    } = useContext(FormContext);
    const { t } = useTranslation();

    return (
        <>
            <div className="mdCustomPadding">
                <TopMenu title={t('step3.heading')} hideHelp videoLink={videoURL.step3Video} />
            </div>
            <Wrapper>
                <HeadArea style={{ marginBottom: '26px' }}>
                    <Typography variant="h4" className="titleStyle margintitleStyle">
                        {t('step3.sub-heading')}
                    </Typography>
                    <Typography className="onboardingWarningText">
                        You may skip this step for now and complete it later by clicking{' '}
                        <a href="#" onClick={skipStep}>
                            here
                        </a>
                        .
                        <br />
                        You can update this information later by going to the Home Hub and select My Settings and then
                        click on the appropriate box.
                        <br />
                        <br />
                    </Typography>
                    <Typography variant="h6" className="commonText">
                        {t('step3.description')}
                    </Typography>
                </HeadArea>
                <Grid container>
                    {categoryGroups?.map((categoryGroup) => (
                        <AccordionStyle
                            key={categoryGroup.id}
                            expanded={openAccordionsStep3.includes(categoryGroup.name)}
                            onChange={onCategoryAccordionClick(categoryGroup.name)}
                        >
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className="commonHeading">{categoryGroup.name}</Typography>
                                <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 32, height: 32 }} />
                                <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 32, height: 32 }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <ListAddCategory
                                    categoryGroupId={categoryGroup.id}
                                    categories={categoryGroup.categories}
                                />
                            </AccordionDetails>
                        </AccordionStyle>
                    ))}
                </Grid>
            </Wrapper>
        </>
    );
}
