import { Grid, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useContext } from 'react';
import videoURL from 'constants/helpVideoUrl';
import Iconify from '../../../../../components/Iconify';
import TopMenu from '../../../../../components/TopMenu';
import { Wrapper } from '../../../setup/styled-components';
import { FormContext } from '../../context/StepperContext';
import { AccordionStyle } from '../../styled-components';
import BudgetTable from '../Budget';
import ListAddBudget from '../ListAddBudget';
import { HeadArea } from './styled-components';

export default function Step5({ skipStep }) {
    const { categoriesMasterData: categoryGroups } = useContext(FormContext);

    const filteredCategoryGroups = categoryGroups.filter((catGrp) =>
        ['Income/Cash Inflows', 'Expenses/Cash Outflows'].includes(catGrp.name)
    );

    const getTotal = (subCategories) =>
        subCategories.reduce((total, item) => {
            const budget = item.budget.toString().replace(/[^0-9.-]+/g, '');
            total += Number(budget);
            return total;
        }, 0);

    const getTotalBudget = (categoryGroup) => {
        const filteredCategories = categoryGroup.categories.filter((cat) => cat.isSelected);
        let total = 0;
        filteredCategories.forEach((fc) => {
            total += getTotal(fc.subCategories);
        });
        return total;
    };

    const getTotalInflow = () => {
        const incomeCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Income/Cash Inflows');
        if (incomeCat) {
            return getTotalBudget(incomeCat);
        }
        return 0;
    };

    const getTotalOutflow = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        if (expenseCat) {
            return getTotalBudget(expenseCat);
        }
        return 0;
    };

    const getDiscNonDiscOutflow = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        let discretionaryOutflow = 0;
        let nonDiscretionaryOutflow = 0;
        if (expenseCat) {
            const filteredCategories = expenseCat.categories.filter(
                (cat) => cat.isSelected && cat.name !== 'Loan/Lease payments'
            );
            filteredCategories.forEach((fc) => {
                discretionaryOutflow += getTotal(fc.subCategories.filter((c) => c.isDiscretionary));
                nonDiscretionaryOutflow += getTotal(fc.subCategories.filter((c) => !c.isDiscretionary));
            });
        }
        return {
            discretionaryOutflow,
            nonDiscretionaryOutflow,
        };
    };

    const getLoanRepayment = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        if (expenseCat) {
            const loanCategory = expenseCat.categories.find(
                (cat) => cat.isSelected && cat.name === 'Loan/Lease payments'
            );
            if (loanCategory) {
                return getTotal(loanCategory.subCategories);
            }
        }
        return 0;
    };

    const cashInflow = getTotalInflow();
    const cashOutflow = getTotalOutflow();
    const totalLoanRepayment = getLoanRepayment();
    const { discretionaryOutflow, nonDiscretionaryOutflow } = getDiscNonDiscOutflow();

    return (
        <>
            <div className="mdCustomPadding">
                <TopMenu title={'Step 5'} hideHelp videoLink={videoURL.step5Video} />
            </div>
            <Wrapper>
                <HeadArea style={{ marginBottom: '26px' }}>
                    <Typography variant="h4" className="titleStyle margintitleStyle">
                        Add monthly budget details
                    </Typography>
                    <Typography className="onboardingWarningText">
                        You may skip this step for now and complete it later by clicking{' '}
                        <a href="#" onClick={skipStep}>
                            here
                        </a>
                        .
                        <br />
                        You can update this information later by going to the Home Hub and select My Settings and then
                        click on the appropriate box.
                        <br />
                    </Typography>
                </HeadArea>
                <Grid container>
                    {filteredCategoryGroups.map((categoryGroup, index) => (
                        <AccordionStyle key={categoryGroup.id} defaultExpanded={index === 0 && true}>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className="commonHeading">{categoryGroup.name}</Typography>
                                <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                                <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <ListAddBudget
                                    categoryGroup={categoryGroup}
                                    categories={categoryGroup.categories}
                                    cashInflow={cashInflow}
                                    cashOutflow={cashOutflow}
                                />
                            </AccordionDetails>
                        </AccordionStyle>
                    ))}
                    <AccordionStyle>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className="commonHeading">Summary</Typography>
                            <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                            <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <BudgetTable
                                cashInflow={cashInflow}
                                cashOutflow={cashOutflow}
                                totalLoanRepayment={totalLoanRepayment}
                                discretionaryOutflow={discretionaryOutflow}
                                nonDiscretionaryOutflow={nonDiscretionaryOutflow}
                            />
                        </AccordionDetails>
                    </AccordionStyle>
                </Grid>
            </Wrapper>
        </>
    );
}
