import { styled, Typography } from '@mui/material';

export const HeadArea = styled('div')(({ theme }) => ({
    '& div': {
        '& .MuiTypography-h3': {
            width: 580,
            [theme.breakpoints.down('lg')]: {
                width: 500,
            },
            [theme.breakpoints.down('md')]: {
                width: 380,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                lineHeight: '30px',
            },
        },
        '& .MuiTypography-subtitle1': {
            width: '72%',
            paddingBottom: '30px',
            fontSize: 20,
            [theme.breakpoints.down('xl')]: {
                fontSize: 16,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    },
}));
  
export const Title = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.primary.light,
    paddingBottom: 48,
}));